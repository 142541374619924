<template>
  <!-- 品牌类型 -->
  <div>
    <div v-if="isShow == 0">
      <bread-crumb :crumbData="['品牌类型']" :chooseCrumbData="1" reloadComponent="brandTypeMain"></bread-crumb>
      <search-case :searchData="searchData" @inquireBtnClick="inquireBtnClick"></search-case>
      <div class="tableBtn">
        <el-button type="danger" size="mini" @click="deleteList">
          <div style="display: flex; align-items: center; font-size: 14px">
            <span class="spriteChart_delete"></span>
            <span style="margin-left: 5px">批量删除</span>
          </div>
        </el-button>
        <el-button type="primary" size="mini" @click="isShow = 1">
          <div style="display: flex; align-items: center; font-size: 14px">
            <span class="spriteChart_add"></span>
            <span style="margin-left: 5px">新增</span>
          </div>
        </el-button>
      </div>
      <div style="padding: 10px 20px">
        <el-table ref="multipleTable" border v-loading="isLoading" :data="tableData" tooltip-effect="dark"
          style="width: 100%; border: 1px solid #e5e5e5" @selection-change="handleSelectionChange">
          <el-table-column type="selection" align="center"> </el-table-column>
          <el-table-column prop="id" sortable label="ID" align="center">
          </el-table-column>
          <el-table-column prop="create_time" sortable label="日期" align="center">
          </el-table-column>
          <el-table-column prop="column_name" label="名称" align="center">
          </el-table-column>
          <el-table-column prop="sort" sortable label="排序" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="handleEdit(scope.row.id)">
                <div style="display: flex; align-items: center; font-size: 14px">
                  <span class="table_spriteChart_amend"></span>
                </div>
              </el-button>
              <span style="margin: 0 10px"></span>
              <el-button type="text" size="mini" @click="handleDelete(scope.row.id)">
                <div style="display: flex; align-items: center; font-size: 14px">
                  <span class="table_spriteChart_delete"></span>
                </div>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span>显示第&nbsp;{{ (currentPage - 1) * 20 + 1 }}&nbsp;至&nbsp;{{
            (currentPage - 1) * 10 + tableData.length
          }}&nbsp;项结果，共&nbsp;{{ tabletotalNum }}&nbsp;项</span>

          <div style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            ">
            <el-pagination background layout="prev, pager, next" :total="tabletotalNum" :current-page="currentPage"
              @current-change="currentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <addData v-if="isShow == 1" @close="onClose"></addData>
    <compile v-if="isShow == 2" @close="onClose" :compile="compileId"></compile>
  </div>
</template>
<script>
export default {
  name: "brandType",
  components: {
    addData: () => import("./childrens/addData.vue"),
    compile: () => import("./childrens/compile.vue"),
  },
  data() {
    return {
      isShow: 0,

      searchData: {
        date: true,
        isInquireValue: true,
        inquirePlaceholder: "输入要查询的内容",
        isInquireBtn: true,
      },

      isLoading: true,
      tableData: [],
      multipleSelection: [],

      tabletotalNum: 0,
      currentPage: 1,
      currentLength: 20,
      perPage: 0,

      // 子页面数据
      compileId: "",
      commercial_id: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.inquireList();
  },
  computed: {},
  methods: {
    // 分页 -- 当前页改变传值
    currentChange(val) {
      this.currentPage = val;
      this.inquireList();
    },
    // 全部数据
    inquireList() {
      this.$http
        .post("/Brand_Type/lst", {
          currentPage: this.currentPage,
          currentLength: this.currentLength,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          console.log(res);
          this.isLoading = false;
          this.tableData = res.data.data;
          this.tabletotalNum = res.data.count;
        });
    },
    // 删除 -- 批量
    deleteList() {
      if (this.multipleSelection != "") {
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const idArr = this.multipleSelection.map((item) => {
              return item.id;
            });
            this.$http
              .post(
                "/Brand_Type/del",
                this.$qs.stringify({
                  id: idArr,
                })
              )
              .then((res) => {
                this.$message({
                  type: res.data.status,
                  message: res.data.msg,
                });
                this.inquireList();
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.error("请选择需删除的数据！");
      }
    },
    // 删除 -- 单条
    handleDelete(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post(
              "/Brand_Type/del",
              this.$qs.stringify({
                id,
              })
            )
            .then((res) => {
              this.$message({
                type: res.data.status,
                message: res.data.msg,
              });
              this.inquireList();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 查询传值
    inquireBtnClick(dateVal, inquireVal) {
      this.$http
        .post(
          "/Brand_Type/type_search",
          this.$qs.stringify({
            firstTime: dateVal[0],
            lastTime: dateVal[1],
            search: inquireVal,
          })
        )
        .then((res) => {
          this.tableData = res.data;
        });
    },
    // 修改数据
    handleEdit(id) {
      this.isShow = 2;
      this.compileId = id;
    },
    // 关闭新增页面
    onClose(data) {
      this.isShow = data;
      console.log(1);
      this.inquireList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
  watch: {},
};
</script>
<style scoped lang="less">
.tableBtn {
  padding: 5px 20px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
</style>
<style lang="less">
.el-table__cell {
  border-bottom: 1px solid #e5e5e5 !important;
  border-right: 1px solid #e5e5e5 !important;
  box-sizing: content-box !important;
  color: #333 !important;
}

.el-table__header {
  width: 100%;
}

.el-table__body {
  width: 100%;
}

// .el-table {
//   table-layout: fixed !important;
//   width: 100% !important;
// }
</style>
